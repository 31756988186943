/*= ========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

export default {
  ADD_EVENT(state, event) {
    state.events.push(event);
  },
  ADD_RDV(state, event) {
    state.events.push(event);
  },
  SET_EVENTS(state, events) {
    state.events = events;
  },

  SET_EVENT(state, event) {
    state.event = event;
  },
  UPDATE_EVENT(state, event) {
    const eventIndex = state.events.findIndex((e) => e.id === event.id);
    Object.assign(state.events[eventIndex], event);
  },
  REMOVE_EVENT(state, eventId) {
    const eventIndex = state.events.findIndex((e) => e.id === eventId);
    state.events.splice(eventIndex, 1);
  },
  REMOVE_RDV(state, eventId) {
    const eventIndex = state.events.findIndex((e) => e.id === eventId);
    state.events.splice(eventIndex, 1);
  },
};
