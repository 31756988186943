import moment from 'moment';
import urlBuilder from '@/plugins/urlBuilder';
import axios from '@/axios';

export default {
  // EVENTS
  async fetchEvents({ commit }, {
    idNegociateur, startDate, endDate, idOrder = null,
  }) {
    const url = urlBuilder('/api/evenement/getAllAppointments/:agentId')
      .appendParams(':agentId', idNegociateur)
      .appendQuery('startDate', startDate)
      .appendQuery('endDate', endDate);

    if (idOrder) {
      url
        .changeUrl('/api/evenement/getAllAppointmentsByOrder/:agentId/:orderId')
        .appendParams(':agentId', idNegociateur)
        .appendParams(':orderId', idOrder);
    }

    const { data } = await axios.get(url.format());
    const events = data.map((value) => ({
      id: value.id,
      start: moment(value.start).format('YYYY-MM-DD HH:mm'),
      end: moment(value.end).format('YYYY-MM-DD HH:mm'),
      title: value.title,
      content: value.label,
      label: value.label,
      class: value.rendezVous !== null ? 'RDV' : '',
    }));
    commit('SET_EVENTS', events);
  },
  async getEvent({ commit }, { idNegociateur, idEvent }) {
    const { data } = await axios.get(`/api/evenement/getAppointment/${idNegociateur}/${idEvent}`);
    commit('SET_EVENT', {
      id: data.id,
      start: moment(data.start).format('YYYY-MM-DD HH:mm'),
      end: moment(data.end).format('YYYY-MM-DD HH:mm'),
      title: data.title,
      rendezVous: data.rendezVous,
      desc: data.desc,
    });
  },
  async editEvent({ commit }, event) {
    await axios.post(`/api/evenement/modifyAppointment/${event.user}`, event);
    Object.assign(event, { start: new Date(event.start) });
    Object.assign(event, { end: new Date(event.end) });
    commit('UPDATE_EVENT', event);
  },
  async removeEvent({ commit }, event) {
    await axios.post(`/api/evenement/deleteAppointment/${event.user}`, event);
    commit('REMOVE_EVENT', event.id);
  },

  // APPOINTMENTS
  async addAppointment({ commit }, event) {
    await axios.post('/api/rendez_vouses', event);
    commit('ADD_RDV', event);
  },
  async editAppointment({ commit }, event) {
    await axios.patch(`/api/rendez_vouses/${event.idRdv}`, event, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    });
    commit('UPDATE_EVENT', event);
  },
  async removeAppointment({ commit }, eventId) {
    await axios.delete(`/api/rendez_vouses/${eventId}`);
    commit('REMOVE_RDV', eventId);
  },
};
